(function() {
    var inputs = document.querySelectorAll( '.false-input-file' );
    var form = document.forms["modale-offre-emploi"];
    var messageCV = document.getElementById('message-cv');
    Array.prototype.forEach.call( inputs, function( input ) {
        var label	 = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener( 'change', function( e )
        {
            var fileName = '';
            if( this.files && this.files.length > 1 ) {
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            } else {
                fileName = e.target.value.split("\\").pop();
            }
            if( fileName ) {
                label.classList.add('filled');
                label.querySelector( 'span' ).innerHTML = fileName;
            } else {
                label.classList.remove('filled');
                label.innerHTML = labelVal;
            }
        });
    });

    if (undefined !== form && null !== form) {
        form.addEventListener('submit', function(e){
            var fileCV = form['votre-cv'].value;
            messageCV.innerHTML = '';
            if ('' === fileCV) {
                e.preventDefault();
                var div = document.createElement('div');
                div.classList.add("wpcf7-response-output", "wpcf7-validation-errors");
                div.innerHTML = 'Veuillez ajouter votre CV';
                messageCV.append(div);
            }
        });
    }

    // Alerte mail validation
    var alertForm = document.getElementById('alert-form-ano');
    if (undefined !== alertForm && null !== alertForm) {
        submitAlertForm(alertForm);
    }

    function submitAlertForm(form) {
        form.addEventListener('submit', function(e){
            var alertFormRGPD = form['consent_rgpd'];
            if (!(alertFormRGPD.checked)) {
                e.preventDefault();
                alertFormRGPD.nextElementSibling.nextElementSibling.classList.remove('hidden');
            }
        });
        document.getElementById('alert-form-submit').addEventListener('click',function(e) {
            var alertFormRGPD = form.querySelector('#consent_rgpd');
            if (!(alertFormRGPD.checked)) {
                e.preventDefault();
                var errorMessage = alertFormRGPD.nextElementSibling.nextElementSibling;
                errorMessage.classList.remove('hidden');
            }
        })
    }

    // Form Photos Function INIT
    var photosContainer = document.querySelector('.photos-container');
    if (undefined !== photosContainer && null !== photosContainer) {
        photosEstimation(photosContainer);
    }

    // fonction d'appel au serveur pour importer/supprimer les images
    function photosEstimation(photosContainer) {

        // Add attribut required on consent (document loading)
        var consentrgpd = photosContainer.querySelectorAll( 'input[name="consent-rgpd"]');
        if(consentrgpd.length > 0) {
            consentrgpd[0].setAttribute('required', 'required');
        }

        // Call onChange file - upload
        document.addEventListener('change',function(e){
            if(e.target && e.target.type == 'file'){
                e.preventDefault();
                doAjaxUploadPhotos(e.target, "upload", e);
            }
        });

        // Call onClick remove button - remove upload/tile
        document.addEventListener('click',function(e){
            if(e.target && e.target.classList.contains('photo-input-remove')){
                e.preventDefault();
                var parent = e.target.closest('.photo-input');
                if (parent.classList.contains('success')) {
                    doAjaxUploadPhotos(e.target, "remove", e);
                } else {
                    parent.remove();
                }
            }

            // Call onClick CRON - remove uploads rgpd 3y (test)
            /*if(e.target && e.target.classList.contains('cron-photo-remove')){
                e.preventDefault();
                doAjaxUploadPhotos(e.target, "cron_remove", e);
            }*/
        });

        // Call onSubmit form - link url in email
        var formPhotos = photosContainer.closest('form');
        if(formPhotos.length > 0) {
            formPhotos.addEventListener( 'submit', function( e ) {
                e.preventDefault();
                var currentForm = this;
                var inputsPhotos = photosContainer.querySelectorAll( '.photo-input.success input[type="hidden"]');
                currentForm.classList.remove("error");
                if(inputsPhotos.length > 0) {
                    Array.prototype.forEach.call( inputsPhotos, function( inputPhoto ) {
                        var textareaType = inputPhoto.closest('div[data-zone]').getAttribute('data-zone');
                        var textareaSelect = formPhotos.querySelectorAll('textarea[name*="' + textareaType + '"]')[0];
                        textareaSelect.value = textareaSelect.value + inputPhoto.value + "\n\n";
                    });
                    e.currentTarget.submit();
                } else {
                    currentForm.classList.add("error");
                    setTimeout(function(){
                        currentForm.classList.remove("submited");
                    }, 1000);
                }
            });
        }
    }

    function doAjaxUploadPhotos(element, mode, event) {
        // VARIABLES
        var parent = element.closest('.photo-input');
        var estimationSessionStorage = window.sessionStorage.getItem('estimation');
        if(estimationSessionStorage !== undefined && estimationSessionStorage !== null) {
            var email = JSON.parse(estimationSessionStorage)['votre-email'];
            var date = JSON.parse(estimationSessionStorage)['date'];
            var formdata = new FormData();
            // PROGRESS
            if(parent !== undefined && parent !== null) {
                parent.classList.remove("success");
                parent.classList.remove("error");
                parent.classList.add("inprogress");
            }
            // DATAS TO AJAX
            formdata.append("action", "import_photos_estimation");
            formdata.append("mode", mode);
            formdata.append("security", formsAjax.security);
            formdata.append("email", email);
            formdata.append("date", date);
            switch (mode) {
                case 'upload':
                    formdata.append("name", element.getAttribute('name'));
                    formdata.append("file", element.files[0]);
                    formdata.append("contentType", false);
                    formdata.append("processData", false);
                    break;
                case 'remove':
                    formdata.append("name", parent.querySelectorAll('input[type="file"]')[0].getAttribute('name'));
                    formdata.append("file", parent.querySelectorAll('label .filename')[0].textContent);
                    break;
            }
            // REQUEST AJAX
            var request = new XMLHttpRequest();
            request.open("POST", formsAjax.ajaxurl);
            request.overrideMimeType("text/plain; charset=UTF-8");
            request.send(formdata);
            request.addEventListener('load', function(){
                var json = JSON.parse(this.response);
                if (200 === this.status && json.success !== false) {
                    // VARIABLES
                    var inputName = Object.keys(json.data)[0];
                    var inputValue = json.data[inputName];
                    switch (mode) {
                        case 'upload':
                            // FILENAME IN INPUT
                            var filename = event.target.value.split("\\").pop();
                            parent.querySelectorAll('label .filename')[0].textContent = filename;
                            parent.querySelectorAll('input[type="hidden"]')[0].value = inputValue;
                            // CLASS CHANGE
                            parent.closest('form').classList.remove("error");
                            parent.classList.add("success");
                            break;
                        case 'remove':
                            // FILENAME IN INPUT
                            parent.querySelectorAll('label .filename')[0].textContent = parent.querySelectorAll('label .filename')[0].getAttribute('label');
                            parent.querySelectorAll('input[type="hidden"]')[0].value = "";
                            parent.querySelectorAll('input[type="file"]')[0].value = "";
                            // CLASS CHANGE
                            parent.classList.remove("success");
                            break;
                    }
                    if(parent !== undefined && parent !== null) {
                        parent.classList.remove("inprogress");
                    }
                } else {
                    parent.classList.add("error");
                    parent.classList.remove("inprogress");
                    parent.querySelectorAll('label .filename')[0].textContent = json.data.error;
                }
            });
        } else {
            parent.classList.add("error");
            var errorMessage = 'Aucune estimation préalable n\'a été détéctée, veuillez effectuer une estimation avant de déposer des photos !';
            parent.querySelectorAll('label .filename')[0].textContent = errorMessage;
        }
    }
})();